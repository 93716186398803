import Vue from 'vue';
import mixins from 'vue-typed-mixins';
import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
import _ from 'lodash';

import StudyResultDialog from '@/user/components/study/dialog/result/StudyResultDialog.vue';

import RoutesMixin from '@/user/mixins/RoutesMixin';
import StudyMixin from '@/user/mixins/StudyMixin';
import WindowAdjustMixin from '@/user/mixins/WindowAdjustMixin';

import getQuestion from '@/master/clean';

/** 行数情報 */
enum RowType {
  row3 = 3,
  row5 = 5,
  row7 = 7,
};

/** セル種別 */
enum CellType {
  wall,
  dust,
  clean
};

/** コマンド */
enum Command {
  up    = 'up',
  down  = 'down',
  left  = 'left',
  right = 'right',
  loop2 = '2',
  loop3 = '3',
  loop4 = '4'
};

/** 位置情報 */
type Coordinate = {
  x: number;
  y: number;
};

/** ドラッグアイテム */
type DragItem = {
  command: Command;
  initialX: number;
  initialY: number;
  x: number;
  y: number;
  w: number;
  h: number;
  isDragging: boolean;
};

/** 回答欄枠数情報 */
type AnswerBoxFrame = {
  colCount: number;
  rowCount: number;
};

/** 回答欄矩形情報 */
type AnswerBoxRect = {
  top: number;
  left: number;
  bottom: number;
  right: number;
};

/** 回答情報 */
type AnswerCommand = {
  command: Command;
  originalIndex: number;
};

/** 回答アニメーション情報 */
type AnswerAnimationInfo = {
  cell?: Coordinate;
  answerBoxRowIndex: number;
  isError: boolean;
};

/** 入力補助情報 */
type AssitanceTarget = {
  command: Command;
  answerBoxRow: number;
  answerBoxCol: number;
};

/** 入力補助矩形情報 */
type AssistanceRect = {
  deg: number;
  top: number;
  left: number;
  width: number;
  padding: number;
};

/** 入力補助 */
type Assistance = {
  target: AssitanceTarget;
  rect: AssistanceRect;
};

/** チュートリアル情報 */
type TutorialData = {
  sectionNo: number;
  unitNo: number;
  assitanceTargets: AssitanceTarget[];
};

/** チュートリアル情報 */
const TUTORIAL_DATA: TutorialData[] = [
  {
    sectionNo: 1,
    unitNo: 1,
    assitanceTargets: [
      { command: Command.up, answerBoxRow: 0, answerBoxCol: 0 }
    ]
  },
  {
    sectionNo: 1,
    unitNo: 2,
    assitanceTargets: [
      { command: Command.down, answerBoxRow: 0, answerBoxCol: 0 },
      { command: Command.left, answerBoxRow: 1, answerBoxCol: 0 }
    ]
  },
  {
    sectionNo: 3,
    unitNo: 1,
    assitanceTargets: [
      { command: Command.loop4, answerBoxRow: 0, answerBoxCol: 0 },
      { command: Command.right, answerBoxRow: 1, answerBoxCol: 1 },
      { command: Command.loop4, answerBoxRow: 2, answerBoxCol: 0 },
      { command: Command.up,    answerBoxRow: 3, answerBoxCol: 1 }
    ]
  },
  {
    sectionNo: 3,
    unitNo: 2,
    assitanceTargets: [
      { command: Command.loop4, answerBoxRow: 0, answerBoxCol: 0 },
      { command: Command.left,  answerBoxRow: 1, answerBoxCol: 1 },
      { command: Command.up,    answerBoxRow: 2, answerBoxCol: 0 },
      { command: Command.loop4, answerBoxRow: 3, answerBoxCol: 0 },
      { command: Command.right, answerBoxRow: 4, answerBoxCol: 1 }
    ]
  },
  {
    sectionNo: 4,
    unitNo: 1,
    assitanceTargets: [
      { command: Command.loop4, answerBoxRow: 0, answerBoxCol: 0 },
      { command: Command.right, answerBoxRow: 1, answerBoxCol: 1 },
      { command: Command.up,    answerBoxRow: 2, answerBoxCol: 1 },
    ]
  },
  {
    sectionNo: 4,
    unitNo: 2,
    assitanceTargets: [
      { command: Command.loop3, answerBoxRow: 0, answerBoxCol: 0 },
      { command: Command.up,    answerBoxRow: 1, answerBoxCol: 1 },
      { command: Command.right, answerBoxRow: 2, answerBoxCol: 1 },
      { command: Command.right, answerBoxRow: 3, answerBoxCol: 0 },
      { command: Command.up,    answerBoxRow: 4, answerBoxCol: 0 },
    ]
  },
  {
    sectionNo: 7,
    unitNo: 1,
    assitanceTargets: [
      { command: Command.loop2, answerBoxRow: 0, answerBoxCol: 0 },
      { command: Command.loop3, answerBoxRow: 1, answerBoxCol: 1 },
      { command: Command.right, answerBoxRow: 2, answerBoxCol: 2 },
      { command: Command.loop2, answerBoxRow: 3, answerBoxCol: 0 },
      { command: Command.loop3, answerBoxRow: 4, answerBoxCol: 1 },
      { command: Command.up,    answerBoxRow: 5, answerBoxCol: 2 },
    ]
  },
  {
    sectionNo: 7,
    unitNo: 2,
    assitanceTargets: [
      { command: Command.loop2, answerBoxRow: 0, answerBoxCol: 0 },
      { command: Command.loop3, answerBoxRow: 1, answerBoxCol: 1 },
      { command: Command.right, answerBoxRow: 2, answerBoxCol: 2 },
      { command: Command.up,    answerBoxRow: 3, answerBoxCol: 0 },
      { command: Command.loop2, answerBoxRow: 4, answerBoxCol: 0 },
      { command: Command.loop3, answerBoxRow: 5, answerBoxCol: 1 },
      { command: Command.left,  answerBoxRow: 6, answerBoxCol: 2 },
    ]
  },
  {
    sectionNo: 8,
    unitNo: 1,
    assitanceTargets: [
      { command: Command.loop2, answerBoxRow: 0, answerBoxCol: 0 },
      { command: Command.loop3, answerBoxRow: 1, answerBoxCol: 1 },
      { command: Command.right, answerBoxRow: 2, answerBoxCol: 2 },
      { command: Command.loop3, answerBoxRow: 3, answerBoxCol: 1 },
      { command: Command.up,    answerBoxRow: 4, answerBoxCol: 2 },
    ]
  },
  {
    sectionNo: 8,
    unitNo: 2,
    assitanceTargets: [
      { command: Command.loop2, answerBoxRow: 0, answerBoxCol: 0 },
      { command: Command.loop3, answerBoxRow: 1, answerBoxCol: 1 },
      { command: Command.up,    answerBoxRow: 2, answerBoxCol: 2 },
      { command: Command.right, answerBoxRow: 3, answerBoxCol: 1 },
      { command: Command.loop2, answerBoxRow: 4, answerBoxCol: 0 },
      { command: Command.right, answerBoxRow: 5, answerBoxCol: 1 },
      { command: Command.loop3, answerBoxRow: 6, answerBoxCol: 1 },
      { command: Command.down,  answerBoxRow: 7, answerBoxCol: 2 },
    ]
  },
];

/** 問題セル定義（壁） */
const CELL_WALL  = '　';
/** 問題セル定義（スタート） */
const CELL_START = 'Ｓ';
/** 問題セル定義（通過可） */
const CELL_VIA   = 'Ｏ';

export default mixins(RoutesMixin, StudyMixin, WindowAdjustMixin).extend({

  name: 'Clean',

  components: {
    VueDraggableResizable,
    StudyResultDialog,
  },

  data(): {
    cellType: typeof CellType;
    cellLayout: CellType[][];
    startCell: Coordinate;
    availableCommands: Command[];
    dragItems: DragItem[];
    answer: {
      frame: AnswerBoxFrame;
      rects: AnswerBoxRect[][];
      commands: (AnswerCommand | null)[][];
      animateInfos: AnswerAnimationInfo[];
      animateIndex: number;
    };
    animationTimer: number | null;
    assistance: Assistance | null;
    isFlashAnswer: boolean;
  } {
    return {
      cellType: CellType,
      cellLayout: [],
      startCell: { x: 0, y: 0 },
      availableCommands: [],
      dragItems: [],
      answer: {
        frame: {
          rowCount: 0,
          colCount: 0
        },
        rects: [],
        commands: [],
        animateInfos: [],
        animateIndex: -1,
      },
      animationTimer: null,
      assistance: null,
      isFlashAnswer: false,
    };
  },

  computed: {
    qRowCount(): number {
      return this.cellLayout.length;
    },
    qColCount(): number {
      const qFirstRow = this.cellLayout[0];
      return qFirstRow !== undefined ? this.cellLayout[0].length : 0;
    },
    aRowCount(): number {
      return this.answer.frame.rowCount;
    },
    aColCount(): number {
      return this.answer.frame.colCount;
    },
    cellWidth(): number {
      switch (this.qRowCount) {
        case RowType.row3: return this.calculateRatio(60);
        case RowType.row5: return this.calculateRatio(45);
        case RowType.row7: return this.calculateRatio(30);
        default: return 0;
      }
    },
    currentCellLayout(): { layout: CellType[][], cell: Coordinate } {
      let   cell   = this.startCell;
      const layout = _.cloneDeep(this.cellLayout);
      for (let i = 0; i < this.answer.animateIndex + 1; i++) {
        const info = this.answer.animateInfos[i];
        if (info === undefined || info.isError) break; // 情報がないか、エラーとなった場合はbreak
        if (info.cell === undefined) continue;         // セル情報が無い（ループコマンド）の場合はcontinue
        cell = info.cell;
        layout[info.cell.y][info.cell.x] = CellType.clean;
      }
      return { layout: layout, cell: cell };
    },
    isTutorial(): boolean {
      return TUTORIAL_DATA.some(data => data.sectionNo === this.sectionNo && data.unitNo === this.unitNo);
    },
    assitanceTargets(): AssitanceTarget[] {
      const data = TUTORIAL_DATA.find(data => data.sectionNo === this.sectionNo && data.unitNo === this.unitNo);
      return data !== undefined ? data.assitanceTargets : [];
    },
    assistanceStyle(): object {
      if (this.assistance === null) return {};
      return {
        '-webkit-transform': `rotate(${this.assistance.rect.deg}deg)`,
        transform: `rotate(${this.assistance.rect.deg}deg)`,
        top: `${this.assistance.rect.top}px`,
        left: `${this.assistance.rect.left}px`,
        width: `${this.assistance.rect.width}px`,
        'padding-left': `${this.assistance.rect.padding}px`,
      };
    },
    animationMsec(): number {
      if (this.sectionNo < 3) return 500;
      if (this.sectionNo < 7) return 375;
      return 250;
    }
  },

  created(): void {
    this.initializeStudyVariable();
    this.initializeStudyRequest();
    this.$_initializeQuestion();
  },

  mounted(): void {
    this.onChangeWindowSizeHandler();
    this.startTimer();
  },

  destroyed(): void {
    this.$_clearAnimationTimer();
    this.clearRestTimeInterval();
    this.clearStudyResult();
  },

  methods: {
    getCellBgStyle(type: CellType, x: number, y: number): object {
      if (type === CellType.wall) return {};
      const imageWidth  = 948;
      const borderWidth = 10;

      const width = (imageWidth - borderWidth * (this.qRowCount + 1)) / this.qRowCount;
      const left  = borderWidth + (width + borderWidth) * x;
      const top   = borderWidth + (width + borderWidth) * y;
      const scale = this.cellWidth / (width + borderWidth);
      return {
        'background-image': `url(${require(`@/assets/img/study/clean/bg_cell_${type}.png`)})`,
        'background-position': `-${left}px -${top}px`,
        width: `${width}px`,
        height: `${width}px`,
        transform: `scale(${scale})`,
        'transform-origin': '0 0'
      };
    },
    currentRowCssClass(y: number): string {
      if (this.answer.animateIndex === -1) return '';

      let ret = '';
      const info = this.answer.animateInfos[this.answer.animateIndex];
      if (y === info.answerBoxRowIndex) {
        ret += 'currentRow';
        if (info.isError) ret += ' error';
      }
      return ret;
    },
    animateAnswerForward(): void {
      this.$_clearAnimationTimer();
      if (this.answer.animateInfos.length - 1 <= this.answer.animateIndex) return;
      this.answer.animateIndex++;
    },
    animateAnswerbackward(): void {
      this.$_clearAnimationTimer();
      if (this.answer.animateIndex === -1) return;
      this.answer.animateIndex--;
    },
    resetAnswerAnimation(): void {
      this.$_clearAnimationTimer();
      this.answer.animateIndex = -1;
    },
    startAnswerAnimation(): void {
      this.resetAnswerAnimation();
      this.animationTimer = setTimeout(this.$_animateAnswer, this.animationMsec);
    },
    onDragging(index: number): void {
      if (this.dragItems[index].isDragging) return;
      this.dragItems[index].isDragging = true;
      this.$_clearAnimationTimer();
    },
    async onDragstop(left: number, top: number, index: number): Promise<void> {
      const dragItem = this.dragItems[index];

      // ドラッグ状態を戻す
      dragItem.isDragging = false;

      // 元の位置を保持しておく
      const originX = dragItem.x;
      const originY = dragItem.y;

      // 自分自身が回答に入っていた場合は回答を空にして、行位置を保持しておく
      let originRow = -1;
      this.answer.commands.forEach((answerRow, y) => {
        const x = answerRow.findIndex(a => a !== null && a.originalIndex === index);
        if (x > -1) {
          originRow = y;
          this.answer.commands[y][x] = null;
        }
      });

      // xyの値が変更されないと位置調整しても位置が更新されないので
      // 一度ドラッグ位置に配置して明示的に更新をかける
      dragItem.x = left;
      dragItem.y = top;
      await Vue.nextTick();

      // 配置位置チェック
      let prevAnswerRowIndex = -1;
      let prevAnswerColIndex = -1;
      const x = left + dragItem.w / 2;
      const y = top  + dragItem.h / 2;
      const insertMargin = this.calculateRatio(3);
      loopHitCheck: for (let i = 0; i < this.answer.rects.length; i++) {
        const answerColIndex = this.answer.commands[i].findIndex(a => a !== null);
        for (let j = 0; j < this.answer.rects[i].length; j++) {
          const rect = this.answer.rects[i][j];

          // 回答格納/挿入処理
          const isHit    = rect.left < x && x < rect.right && rect.top + insertMargin < y && y < rect.bottom - insertMargin;
          const isInsert = rect.left < x && x < rect.right && rect.top - insertMargin < y && y < rect.top + insertMargin;
          if (isHit || isInsert) {
            // ドラッグ要素がループコマンド、かつ一つ前の回答が存在する場合は配置可否チェック
            if (this.$_isLoopCommand(dragItem.command) && prevAnswerRowIndex > -1 && prevAnswerColIndex > -1) {
              // 一つ前の回答がループコマンド、かつ右端から2番目（ドラッグ要素が右端になる）の場合は配置不可
              const prevAnswer = this.answer.commands[prevAnswerRowIndex][prevAnswerColIndex]!;
              if (this.$_isLoopCommand(prevAnswer.command) && prevAnswerColIndex === this.aColCount - 2) {
                // 再帰でドラッグ要素を元の位置に戻す
                this.onDragstop(originX, originY, index);
                return;
              }
            }

            // 挿入処理
            if (isInsert) {
              // 回答に自分自身が入っていた場合は入っていた行を削除
              if (originRow > -1) {
                this.answer.commands.splice(originRow, 1);
                if (originRow < i) i--;

              // 回答に自分自身が入っていない場合は最後の行を削除
              } else {
                this.answer.commands.pop();
              }

              // 行の挿入
              this.answer.commands.splice(i, 0, new Array(this.aColCount).fill(null));
            }

            // 同じ行に回答が入っている場合は空にする
            if (answerColIndex > -1) this.answer.commands[i][answerColIndex] = null;

            // 回答を格納してbreak
            this.answer.commands[i][j] = { command: dragItem.command, originalIndex: index };
            break loopHitCheck;
          }
        }

        // 行の回答情報を保持
        if (answerColIndex > -1) {
          prevAnswerRowIndex = i;
          prevAnswerColIndex = answerColIndex;
        }
      }

      // インデント調整（ドラッグ要素の位置調整もここで処理）
      this.$_indentAnswers();
    },
    // WindowAdjustMixin上書き
    async onChangeWindowSizeHandler(): Promise<void> {
      this.$_calcAnswerBoxRects();
      this.$_calcItemSizePosition();
      // チュートリアルの場合は入力補助設定
      this.$_setAssistance();
      // vue-draggable-resizableのparentサイズが変わらないので明示的にイベント通知
      window.dispatchEvent(new Event('resize'));
    },
    $_isLoopCommand(command: Command): boolean {
      return command === Command.loop2 || command === Command.loop3 || command === Command.loop4;
    },
    $_clearAnimationTimer(): void {
      if (this.animationTimer === null) return;
      clearTimeout(this.animationTimer);
      this.animationTimer = null;
    },
    $_initializeQuestion(): void {
      const question = getQuestion(this.sectionNo, this.unitNo);
      // 問題が取得できないときはアラート表示してから例外をthrow
      if (question === null) {
        alert('問題取得エラー');
        throw new Error(`no question master sectionNo:${this.sectionNo}, unitNo:${this.unitNo}`);
      }

      // 掃除機動作確認テーブルのセル設定
      this.cellLayout = [];
      const qRows = question.split('\n');
      for (let y = 0; y < qRows.length; y++) {
        const row    = [];
        const qCells = qRows[y].split('');
        for (let x = 0; x < qCells.length; x++) {
          switch (qCells[x]) {
            case CELL_START:
              row.push(CellType.clean);
              this.startCell = { x: x, y: y };
              break;
            case CELL_VIA:
              row.push(CellType.dust);
              break;
            case CELL_WALL:
              row.push(CellType.wall);
              break;
            default:
              // 定義外の文字だった場合はアラート表示してから例外をthrow
              alert('マスタエラー');
              throw new Error('illegal question character');
          }
        }
        this.cellLayout.push(row);
      }

      // 回答欄枠数設定
      switch (this.qRowCount) {
        case RowType.row3:
          this.answer.frame = { colCount: 1, rowCount: 5 };
          break;
        case RowType.row5:
          this.answer.frame = { colCount: 2, rowCount: 7 };
          break;
        case RowType.row7:
          this.answer.frame = { colCount: 3, rowCount: 11 };
          break;
      }

      // 回答欄
      this.answer.commands = this.$_createEmptyAnswers();

      // 掃除機の操作コマンド追加
      this.availableCommands = [Command.up, Command.down, Command.left, Command.right];
      if (this.qRowCount > RowType.row3) this.availableCommands = this.availableCommands.concat([Command.loop2, Command.loop3, Command.loop4]);
      this.dragItems = [];
      for (const command of this.availableCommands) {
        for (let i = 0; i < this.answer.frame.rowCount; i++) {
          this.dragItems.push({
            command: command,
            initialX: 0,
            initialY: 0,
            x: 0,
            y: 0,
            w: 1,
            h: 1,
            isDragging: false
          });
        }
      }
    },
    $_createEmptyAnswers(): (AnswerCommand | null)[][] {
      const ret = [];
      for (let i = 0; i < this.aRowCount; i++) {
        const row = new Array(this.aColCount).fill(null);
        ret.push(row);
      }
      return ret;
    },
    $_calcAnswerBoxRects(): void {
      this.answer.rects = [];
      const elements = this.$refs.answerBoxes as HTMLElement[];
      if (elements === undefined || elements.length === 0) return; // 回答欄が存在しない場合はreturn

      const parent = elements[0].offsetParent as HTMLElement;
      let row: AnswerBoxRect[] = [];
      elements.forEach((elm, i) => {
        const top  = parent.offsetTop + elm.offsetTop;
        const left = parent.offsetLeft + elm.offsetLeft;
        row.push({
          top:    top,
          left:   left,
          bottom: top + elm.offsetHeight,
          right:  left + elm.offsetWidth,
        });

        if (i % this.aColCount === this.aColCount - 1) {
          this.answer.rects.push(row);
          row = [];
        }
      });
    },
    $_calcItemSizePosition(): void {
      let itemWH    = 0;
      let boxLeft   = 0;
      let boxTop    = 0;
      let boxWidth  = 0;
      let boxHeight = 0;
      switch (this.qRowCount) {
        case RowType.row3:
          itemWH    = this.calculateRatio(50);
          boxLeft   = this.calculateRatio(this.isPortrait ? 93 : 350);
          boxTop    = this.calculateRatio(this.isPortrait ? 276 : 51);
          boxWidth  = this.calculateRatio(72);
          boxHeight = this.calculateRatio(221);
          break;
        case RowType.row5:
          itemWH    = this.calculateRatio(32);
          boxLeft   = this.calculateRatio(this.isPortrait ? 75 : 350);
          boxTop    = this.calculateRatio(this.isPortrait ? 276 : 46);
          boxWidth  = this.calculateRatio(54);
          boxHeight = this.calculateRatio(239);
          break;
        case RowType.row7:
          itemWH    = this.calculateRatio(28);
          boxLeft   = this.calculateRatio(this.isPortrait ? 75 : 350);
          boxTop    = this.calculateRatio(this.isPortrait ? 276 : 44);
          boxWidth  = this.calculateRatio(36);
          boxHeight = this.calculateRatio(253);
          break;
      }

      const marginX = (boxWidth - itemWH) / 2;
      const marginY = (boxHeight - itemWH * this.availableCommands.length) / (this.availableCommands.length + 1);
      const x       = boxLeft + marginX;
      let   y       = boxTop + marginY;
      let   tmpCmd  = this.dragItems[0].command;
      this.dragItems.forEach(item => {
        if (tmpCmd !== item.command) {
          y += itemWH + marginY;
          tmpCmd = item.command;
        }

        item.initialX = x;
        item.initialY = y;
        item.x        = x;
        item.y        = y;
        item.w        = itemWH;
        item.h        = itemWH;
      });

      // 回答に入っているドラッグ要素の位置調整
      this.$_setItemPositionFromAnswers();
    },
    $_createAnswerAnimateInfos(): void {
      this.answer.animateIndex = -1;
      this.answer.animateInfos = [];

      /** セル位置の不正チェック関数 */
      const isIllegalCell = (cell: Coordinate): boolean => {
        const x = cell.x;
        const y = cell.y;
        return x < 0 || y < 0 || this.qColCount < x + 1 || this.qColCount < y + 1 || this.currentCellLayout.layout[y][x] === CellType.wall;
      };

      /** アニメーション情報追加関数 */
      const addAnswerAnimateInfo = (isError: boolean, answerBoxRowIndex: number, cell?: Coordinate): void => {
        this.answer.animateInfos.push({
          isError: isError,
          answerBoxRowIndex: answerBoxRowIndex,
          cell: cell
        });
      };

      /** アニメーション情報生成関数 */
      const create = (answers: (AnswerCommand | null)[][], tempCell: Coordinate, startRowIndex: number): void => {
        for (let i = 0; i < answers.length; i++) {
          const colIndex = answers[i].findIndex(a => a !== null);
          const command  = answers[i][colIndex]!.command;
          const answerBoxRowIndex = startRowIndex + i;

          // ループコマンドの場合
          if (this.$_isLoopCommand(command)) {
            // ループ終わりまで抽出
            const answerInLoop = [];
            for (let j = i + 1; j < answers.length; j++) {
              const colIndexInLoop = answers[j].findIndex(a => a !== null);
              // 回答が同じ列に格納されていたらループ終わり
              if (colIndex === colIndexInLoop) break;
              answerInLoop.push(answers[j]);
            }
            // 抽出した回答をループ回数分再帰処理
            for (let k = 0; k < Number(command); k++) {
              addAnswerAnimateInfo(false, answerBoxRowIndex); // ここでループコマンドも詰めておく
              create(answerInLoop, tempCell, answerBoxRowIndex + 1);
              // アニメーション情報にエラーが入っていたらreturn
              if (this.answer.animateInfos.some(info => info.isError)) return;
            }
            i += answerInLoop.length;

          // 矢印コマンドの場合
          } else {
            // セル位置を進める
            switch (command) {
              case Command.up:
                tempCell.y--;
                break;
              case Command.down:
                tempCell.y++;
                break;
              case Command.left:
                tempCell.x--;
                break;
              case Command.right:
                tempCell.x++;
                break;
            }
            // セル位置が不正な場合はエラーを設定してreturn
            if (isIllegalCell(tempCell)) {
              addAnswerAnimateInfo(true, answerBoxRowIndex);
              return;
            }
            // セル位置を格納
            addAnswerAnimateInfo(false, answerBoxRowIndex, _.clone(tempCell));
          }
        }
      };

      const tempCell = _.cloneDeep(this.startCell);
      const answers  = this.answer.commands.filter(row => row.findIndex(a => a !== null) > -1); // 回答が存在する行のみ抽出
      create(answers, tempCell, 0);
    },
    $_resetItemPosition(index: number): void {
      const item = this.dragItems[index];
      item.x = item.initialX;
      item.y = item.initialY;
    },
    $_setItemPositionFromAnswers(): void {
      this.dragItems.forEach((item, i) => this.$_resetItemPosition(i)); // 一度全て初期位置に戻しておく

      this.answer.commands.forEach((row, y) => {
        row.forEach((col, x) => {
          if (col === null) return; // 回答が無い場合は次へ
          const item = this.dragItems[col.originalIndex];
          const rect = this.answer.rects[y][x];
          item.x = (rect.left + rect.right - item.w) / 2;
          item.y = (rect.top + rect.bottom - item.h) / 2;
        });
      });
    },
    $_indentAnswers(): void {
      const tmpAnswers  = this.$_createEmptyAnswers();
      let   tmpRowIndex = 0;
      let   tmpColIndex = 0;
      let   isAfterLoop = false;
      this.answer.commands.forEach(row => {
        const ansColIndex = row.findIndex(a => a !== null);
        if (ansColIndex === -1) return; // 回答が入っていない場合は次行へ

        // ループ直後の場合は現在列を一つ右に進める
        if (isAfterLoop) tmpColIndex++;
        // ループ直後ではなく、回答が現在列以下の場合は回答列を現在列にする（前行が矢印の場合はその列より右には置けない）
        else if (ansColIndex <= tmpColIndex) tmpColIndex = ansColIndex;

        const answer = row[ansColIndex]!;
        // ループコマンド
        if (this.$_isLoopCommand(answer.command)) {
          // 現在列が右端の場合
          if (tmpColIndex === this.aColCount - 1) {
            tmpColIndex--;           // 列を一つ戻しておく
            if (isAfterLoop) return; // ループ直後の場合は次行へ
          }
          isAfterLoop = true;

        // 矢印コマンド
        } else {
          isAfterLoop = false;
        }

        tmpAnswers[tmpRowIndex][tmpColIndex] = answer;
        tmpRowIndex++;
      });

      // 回答設定とドラッグ要素の位置調整
      this.answer.commands = tmpAnswers;
      this.$_setItemPositionFromAnswers();

      // 回答アニメーション情報生成
      this.$_createAnswerAnimateInfos();

      // チュートリアルの場合は入力補助設定
      this.$_setAssistance();
    },
    $_animateAnswer(): void {
      // 最後までアニメーションしたら正誤判定してreturn
      if (this.answer.animateInfos.length - 1 <= this.answer.animateIndex) {
        this.$_clearAnimationTimer();

        const isCorrect = this.$_judge();
        this.judgeScoreResult(isCorrect);
        return;
      }

      // アニメーションを一つ進めて再帰
      this.answer.animateIndex++;
      this.animationTimer = setTimeout(this.$_animateAnswer, this.animationMsec);
    },
    $_judge(): boolean {
      // アニメーションにエラーが含まれる場合は不正解
      if (this.answer.animateInfos.some(info => info.isError)) {
        return false;
      }

      // 汚れたマスがあれば不正解
      for (const layout of this.currentCellLayout.layout) {
        if (layout.some(type => type === CellType.dust)) return false;
      }

      // ここまで来たら正解
      return true;
    },
    async $_setAssistance(): Promise<void> {
      this.assistance = null;

      // チュートリアル以外はreturn
      if (!this.isTutorial) return;

      this.isFlashAnswer = true;
      const usedItemIndexes: number[] = [];
      for (const target of this.assitanceTargets) {
        // 配置先回答欄の取得
        const answerCommand = this.answer.commands[target.answerBoxRow][target.answerBoxCol];
        if (answerCommand?.command === target.command) {
          // 入力済みの場合はインデックスを使用済みに入れて次へ
          usedItemIndexes.push(answerCommand.originalIndex);
          continue;
        }
        const answerRect = this.answer.rects[target.answerBoxRow][target.answerBoxCol];

        // 配置元コマンドの取得
        let sourceItem = null;
        // まずは回答欄にあるコマンドで使えるものを探す
        const acs = _.flatten(this.answer.commands);
        const ac  = acs.find(ac => ac?.command === target.command && !usedItemIndexes.includes(ac.originalIndex));
        if (ac !== undefined && ac !== null) sourceItem = this.dragItems[ac.originalIndex];
        // 回答欄に使えるコマンドが無い場合はコマンド一覧から探す
        if (sourceItem === null) {
          sourceItem = this.dragItems.filter((item, i) => item.command === target.command && !usedItemIndexes.includes(i))[0];
        }
        // 使えるコマンドが無い場合はreturn
        if (sourceItem === undefined) return;

        // 矢印設定
        const sourceX = sourceItem.x + (sourceItem.w / 2);
        const sourceY = sourceItem.y + (sourceItem.w / 2);
        const answerX = (answerRect.left + answerRect.right) / 2;
        const answerY = (answerRect.top + answerRect.bottom) / 2;
        const width   = Math.sqrt(Math.pow(sourceX - answerX, 2) + Math.pow(sourceY - answerY, 2));
        const deg     = Math.atan2(answerY - sourceY, answerX - sourceX) / (Math.PI / 180);
        this.assistance = {
          target: target,
          rect: {
            deg:     deg,
            top:     sourceY,
            left:    sourceX,
            width:   width,
            padding: sourceItem.w / 2
          }
        };
        this.isFlashAnswer = false; // 矢印表示する場合は採点ボタンは光らせない
        break;
      }
    }
  }

});

import q01_01 from '@/master/clean/q01_01.txt';
import q01_02 from '@/master/clean/q01_02.txt';
import q01_03 from '@/master/clean/q01_03.txt';
import q01_04 from '@/master/clean/q01_04.txt';
import q01_05 from '@/master/clean/q01_05.txt';
import q01_06 from '@/master/clean/q01_06.txt';
import q01_07 from '@/master/clean/q01_07.txt';
import q01_08 from '@/master/clean/q01_08.txt';
import q01_09 from '@/master/clean/q01_09.txt';
import q01_10 from '@/master/clean/q01_10.txt';
import q02_01 from '@/master/clean/q02_01.txt';
import q02_02 from '@/master/clean/q02_02.txt';
import q02_03 from '@/master/clean/q02_03.txt';
import q02_04 from '@/master/clean/q02_04.txt';
import q02_05 from '@/master/clean/q02_05.txt';
import q02_06 from '@/master/clean/q02_06.txt';
import q02_07 from '@/master/clean/q02_07.txt';
import q02_08 from '@/master/clean/q02_08.txt';
import q02_09 from '@/master/clean/q02_09.txt';
import q02_10 from '@/master/clean/q02_10.txt';
import q03_01 from '@/master/clean/q03_01.txt';
import q03_02 from '@/master/clean/q03_02.txt';
import q03_03 from '@/master/clean/q03_03.txt';
import q03_04 from '@/master/clean/q03_04.txt';
import q03_05 from '@/master/clean/q03_05.txt';
import q03_06 from '@/master/clean/q03_06.txt';
import q03_07 from '@/master/clean/q03_07.txt';
import q03_08 from '@/master/clean/q03_08.txt';
import q03_09 from '@/master/clean/q03_09.txt';
import q03_10 from '@/master/clean/q03_10.txt';
import q04_01 from '@/master/clean/q04_01.txt';
import q04_02 from '@/master/clean/q04_02.txt';
import q04_03 from '@/master/clean/q04_03.txt';
import q04_04 from '@/master/clean/q04_04.txt';
import q04_05 from '@/master/clean/q04_05.txt';
import q04_06 from '@/master/clean/q04_06.txt';
import q04_07 from '@/master/clean/q04_07.txt';
import q04_08 from '@/master/clean/q04_08.txt';
import q04_09 from '@/master/clean/q04_09.txt';
import q04_10 from '@/master/clean/q04_10.txt';
import q05_01 from '@/master/clean/q05_01.txt';
import q05_02 from '@/master/clean/q05_02.txt';
import q05_03 from '@/master/clean/q05_03.txt';
import q05_04 from '@/master/clean/q05_04.txt';
import q05_05 from '@/master/clean/q05_05.txt';
import q05_06 from '@/master/clean/q05_06.txt';
import q05_07 from '@/master/clean/q05_07.txt';
import q05_08 from '@/master/clean/q05_08.txt';
import q05_09 from '@/master/clean/q05_09.txt';
import q05_10 from '@/master/clean/q05_10.txt';
import q06_01 from '@/master/clean/q06_01.txt';
import q06_02 from '@/master/clean/q06_02.txt';
import q06_03 from '@/master/clean/q06_03.txt';
import q06_04 from '@/master/clean/q06_04.txt';
import q06_05 from '@/master/clean/q06_05.txt';
import q06_06 from '@/master/clean/q06_06.txt';
import q06_07 from '@/master/clean/q06_07.txt';
import q06_08 from '@/master/clean/q06_08.txt';
import q06_09 from '@/master/clean/q06_09.txt';
import q06_10 from '@/master/clean/q06_10.txt';
import q07_01 from '@/master/clean/q07_01.txt';
import q07_02 from '@/master/clean/q07_02.txt';
import q07_03 from '@/master/clean/q07_03.txt';
import q07_04 from '@/master/clean/q07_04.txt';
import q07_05 from '@/master/clean/q07_05.txt';
import q07_06 from '@/master/clean/q07_06.txt';
import q07_07 from '@/master/clean/q07_07.txt';
import q07_08 from '@/master/clean/q07_08.txt';
import q07_09 from '@/master/clean/q07_09.txt';
import q07_10 from '@/master/clean/q07_10.txt';
import q08_01 from '@/master/clean/q08_01.txt';
import q08_02 from '@/master/clean/q08_02.txt';
import q08_03 from '@/master/clean/q08_03.txt';
import q08_04 from '@/master/clean/q08_04.txt';
import q08_05 from '@/master/clean/q08_05.txt';
import q08_06 from '@/master/clean/q08_06.txt';
import q08_07 from '@/master/clean/q08_07.txt';
import q08_08 from '@/master/clean/q08_08.txt';
import q08_09 from '@/master/clean/q08_09.txt';
import q08_10 from '@/master/clean/q08_10.txt';
import q09_01 from '@/master/clean/q09_01.txt';
import q09_02 from '@/master/clean/q09_02.txt';
import q09_03 from '@/master/clean/q09_03.txt';
import q09_04 from '@/master/clean/q09_04.txt';
import q09_05 from '@/master/clean/q09_05.txt';
import q09_06 from '@/master/clean/q09_06.txt';
import q09_07 from '@/master/clean/q09_07.txt';
import q09_08 from '@/master/clean/q09_08.txt';
import q09_09 from '@/master/clean/q09_09.txt';
import q09_10 from '@/master/clean/q09_10.txt';
import q10_01 from '@/master/clean/q10_01.txt';
import q10_02 from '@/master/clean/q10_02.txt';
import q10_03 from '@/master/clean/q10_03.txt';
import q10_04 from '@/master/clean/q10_04.txt';
import q10_05 from '@/master/clean/q10_05.txt';
import q10_06 from '@/master/clean/q10_06.txt';
import q10_07 from '@/master/clean/q10_07.txt';
import q10_08 from '@/master/clean/q10_08.txt';
import q10_09 from '@/master/clean/q10_09.txt';
import q10_10 from '@/master/clean/q10_10.txt';

const q = [
  [
    q01_01,
    q01_02,
    q01_03,
    q01_04,
    q01_05,
    q01_06,
    q01_07,
    q01_08,
    q01_09,
    q01_10,
  ],
  [
    q02_01,
    q02_02,
    q02_03,
    q02_04,
    q02_05,
    q02_06,
    q02_07,
    q02_08,
    q02_09,
    q02_10,
  ],
  [
    q03_01,
    q03_02,
    q03_03,
    q03_04,
    q03_05,
    q03_06,
    q03_07,
    q03_08,
    q03_09,
    q03_10,
  ],
  [
    q04_01,
    q04_02,
    q04_03,
    q04_04,
    q04_05,
    q04_06,
    q04_07,
    q04_08,
    q04_09,
    q04_10,
  ],
  [
    q05_01,
    q05_02,
    q05_03,
    q05_04,
    q05_05,
    q05_06,
    q05_07,
    q05_08,
    q05_09,
    q05_10,
  ],
  [
    q06_01,
    q06_02,
    q06_03,
    q06_04,
    q06_05,
    q06_06,
    q06_07,
    q06_08,
    q06_09,
    q06_10,
  ],
  [
    q07_01,
    q07_02,
    q07_03,
    q07_04,
    q07_05,
    q07_06,
    q07_07,
    q07_08,
    q07_09,
    q07_10,
  ],
  [
    q08_01,
    q08_02,
    q08_03,
    q08_04,
    q08_05,
    q08_06,
    q08_07,
    q08_08,
    q08_09,
    q08_10,
  ],
  [
    q09_01,
    q09_02,
    q09_03,
    q09_04,
    q09_05,
    q09_06,
    q09_07,
    q09_08,
    q09_09,
    q09_10,
  ],
  [
    q10_01,
    q10_02,
    q10_03,
    q10_04,
    q10_05,
    q10_06,
    q10_07,
    q10_08,
    q10_09,
    q10_10,
  ],
];

const getQuestion = (sectionNo: number, unitNo: number): string => {
  return q[sectionNo - 1][unitNo - 1];
};
export default getQuestion;
